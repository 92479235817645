<template>
  <LoadingPage v-if="loaded" />
  <div v-else class="advanced">
    <NbPageTitle
      :title="$t('advancedPage.title')"
      :subTitle="$t('advancedPage.fistText')"
    />

    <section>
      <NbCard
        id="left-card"
        :title="$t('advancedPage.weightsMeasures')"
        :helpText="$t('advancedPage.settingsAppliedAll')"
      >
        <template #body>
          <p class="body-3">{{ $t("advancedPage.subTitle") }}</p>

          <div class="radio-group">
            <NbRadio
              v-model="weightSystem"
              id="weight"
              :options="weightSystemOptions"
              :name="$t('advancedPage.weightSystem')"
              classes="d-flex flex-column"
              @input="onFormChange"
            />

            <NbRadio
              v-model="measurementSystem"
              id="measurement"
              :options="measurementSystemOptions"
              :name="$t('advancedPage.measurementSystem')"
              classes="d-flex flex-column"
              @input="onFormChange"
            />
          </div>
        </template>
      </NbCard>

      <NbCard
        id="right-card"
        :title="$t('advancedPage.dispatches')"
        :helpText="$t('advancedPage.dispatchesHelpText')"
      >
        <template #body>
          <NbSwitch
            v-model="form.automaticDispatch"
            id="autoSingle"
            :name="$t('advancedPage.autoSingle')"
            @input="onFormChange"
          />
        </template>
      </NbCard>

      <NbCard
        id="cpf-validation"
        :title="$t('advancedPage.cpfValidation')"
        :helpText="$t('advancedPage.cpfHelpText')"
      >
        <template #body>
          <NbSwitch
            v-model="form.cpfValidation"
            id="allowsCpfValidation"
            :name="$t('advancedPage.allowsCpfValidation')"
            @input="onFormChange"
          />
        </template>
      </NbCard>

      <NbHelpCard
        :text-description="$t('printingsPage.ifYouPrefer')"
        redirect-to="/settings/accessibility"
        :redirect-to-description="$t('advancedPage.changePlatformLanguage')"
      />
    </section>

    <NbPageFooter class="mt-4">
      <NbPageFooterText>{{
        $t("components.footer.needHelp")
      }}</NbPageFooterText>
      <NbPageFooterLink href="#"
        >{{
          $t("components.footer.learnMoreAboutWeightAndMeasurements")
        }}.</NbPageFooterLink
      >
    </NbPageFooter>
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbHelpCard from "../../components/cards/NbHelpCard.vue";
import NbPageFooter from "../../components/footers/NbPageFooter.vue";
import NbPageFooterText from "../../components/footers/NbPageFooterText.vue";
import NbPageFooterLink from "../../components/footers/NbPageFooterLink.vue";
import NbRadio from "../../components/buttons/NbRadio.vue";
import NbSwitch from "../../components/buttons/NbSwitch.vue";
import SellerService from "../../services/SellerService";
import UsersService from "../../services/UsersService";

const MEASUREMENT_SYSTEM = {
  imperial: "imperial",
  metric: "metric",
};

const WEIGHT_SYSTEM = {
  kg: "kg",
  lb: "lb",
};

export default {
  name: "CurrencyMeasures",
  components: {
    LoadingPage,
    NbPageTitle,
    NbCard,
    NbHelpCard,
    NbPageFooter,
    NbPageFooterText,
    NbPageFooterLink,
    NbRadio,
    NbSwitch,
  },
  data: () => {
    return {
      loaded: true,
      sellerService: new SellerService(),
      usersService: new UsersService(),
      saveFormDelayed: setTimeout(() => null),
      sellerId: null,
      form: {
        weightSystem: "",
        measurementSystem: "",
        automaticDispatch: false,
        cpfValidation: false,
      },
    };
  },
  computed: {
    weightSystemOptions() {
      return [
        { text: this.$t("kilograms"), value: WEIGHT_SYSTEM.kg },
        { text: this.$t("libras"), value: WEIGHT_SYSTEM.lb },
      ];
    },
    measurementSystemOptions() {
      return [
        { text: this.$t("meters"), value: MEASUREMENT_SYSTEM.metric },
        { text: this.$t("inches"), value: MEASUREMENT_SYSTEM.imperial },
      ];
    },
    measureMode() {
      return this.form.measurementSystem === MEASUREMENT_SYSTEM.imperial
        ? MEASUREMENT_SYSTEM.imperial
        : MEASUREMENT_SYSTEM.metric;
    },
    measurementSystem: {
      get() {
        return this.form.weightSystem === WEIGHT_SYSTEM.kg
          ? MEASUREMENT_SYSTEM.metric
          : MEASUREMENT_SYSTEM.imperial;
      },
      set(val) {
        this.form.measurementSystem = val;
      },
    },
    weightSystem: {
      get() {
        return this.form.measurementSystem === MEASUREMENT_SYSTEM.metric
          ? WEIGHT_SYSTEM.kg
          : WEIGHT_SYSTEM.lb;
      },
      set(val) {
        this.form.weightSystem = val;
      },
    },
  },
  methods: {
    async setMeasurementSystem() {
      const { data: measurementSystem } =
        await this.usersService.getUserMeasurementSystem();
      if (measurementSystem.data === MEASUREMENT_SYSTEM.imperial) {
        this.form.measurementSystem = MEASUREMENT_SYSTEM.imperial;
        this.form.weightSystem = WEIGHT_SYSTEM.lb;
        return;
      }

      this.form.measurementSystem = MEASUREMENT_SYSTEM.metric;
      this.form.weightSystem = WEIGHT_SYSTEM.kg;
    },
    async getSellerSetttings() {
      try {
        this.loaded = true;
        await this.setMeasurementSystem();
        const { data: currentSeller } =
          await this.sellerService.getCurrentSeller();
        this.sellerId = currentSeller.data.id;
        const { data: sellerSettings } =
          await this.sellerService.getSellerSettings(this.sellerId);
        this.form.automaticDispatch = sellerSettings.data.automatic_dispatch;
        this.form.cpfValidation = sellerSettings.data.cpf_validation;
      } finally {
        this.loaded = false;
      }
    },
    onFormChange() {
      clearInterval(this.saveFormDelayed);

      this.saveFormDelayed = setTimeout(() => this.updateSellerSettings(), 500);
    },
    async updateSellerSettings() {
      try {
        this.$root.$emit("isSaving", true);
        await this.sellerService.updateSellerSettings(this.sellerId, {
          seller_setting: {
            measure_mode: this.measureMode,
            automatic_dispatch: this.form.automaticDispatch,
            cpf_validation: this.form.cpfValidation,
          },
        });
      } finally {
        this.$root.$emit("isSaving", false);
      }
    },
  },
  created() {
    this.getSellerSetttings();
  },
};
</script>

<style lang="scss" scoped>
.advanced {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 81px);

  section {
    max-width: 32.75rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      gap: 2rem;
    }
  }
}
</style>
