<template>
  <div class="help-card">
    <p class="body-2 m-0 text-center">
      {{ textDescription }}
      <component
        :is="component.component"
        v-bind="component.attrs"
        class="link-2"
        >{{ redirectToDescription }} ></component
      >
    </p>
  </div>
</template>

<script>
export default {
  props: {
    textDescription: {
      type: String,
      required: true,
    },
    redirectTo: {
      type: [String, Object],
      default: "",
    },
    redirectToDescription: {
      type: String,
      default: "",
    },
  },
  computed: {
    component() {
      const components = {
        anchor: {
          component: "a",
          attrs: {
            href: this.redirectTo,
            target: "_blank",
          },
        },
        routerLink: {
          component: "router-link",
          attrs: {
            to: this.redirectTo,
          },
        },
      };

      if (typeof this.redirectTo == "string" && this.redirectTo.includes(".")) {
        return components.anchor;
      }

      return components.routerLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-card {
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--gray-05);
}
</style>
